import React, { useEffect, FC } from 'react';
import { useApiClient } from 'clients/ApiClient';
declare global {
    interface Window {
        Canny: {
            (...args: any[]): void;
            q?: any[];
        };
    }
}

const CannyFeedbackWidget: FC = (/*user*/) => {
    const apiClient = useApiClient();

    useEffect(() => {
        const setup = async () => {
            if (typeof window.Canny !== 'function') {
                window.Canny = window.Canny || function () {
                    (window.Canny.q = window.Canny.q || []).push(arguments);
                };
            }

            window.Canny('identify', {
                appID: '64adb24b06f84d80567da45d',
            });

            window.Canny('render', {
                boardToken: '76293e31-c1da-6d06-ba45-1981b145f04f',
                basePath: "/feedback",
                ssoToken: (await apiClient.getCannyToken()).data.token,
                theme: 'dark',
            });
        }
        setup()
    }, []);

    return (
        <div data-canny />
    );
}

export default CannyFeedbackWidget;

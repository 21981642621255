import { LinkIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, FormControl, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Spacer, Textarea, Image, useDisclosure, Divider, Wrap, SimpleGrid, ModalHeader } from "@chakra-ui/react";
import { useApiClient } from "clients/ApiClient";
import { ModalProps } from "interfaces/ModalProps";
import { useEffect, useRef, useState } from "react";
import { Image as ImageModel } from 'clients/boutikbooty-api-codegen/api';
import { useImagesContext } from "hooks/useImages";
import ResizeTextarea from "react-textarea-autosize";


export interface CreatePostModalProps {

}

export const CreatePostModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    const apiClient = useApiClient();
    const { imageHistory, getImageHistory } = useImagesContext();

    const { isOpen: isSelectImageOpen, onOpen: onSelectImageOpen, onClose: onSelectImageClose } = useDisclosure();

    const [text, setText] = useState('');
    const [selectedImage, setSelectedImage] = useState<ImageModel | null>(null);
    // const [imagesToSelect, setImagesToSelect] = useState<ImageModel[]>([]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
        event.preventDefault();  // prevent the form from submitting
        // setPrompt('')
        // textAreaRef.current?.blur();
        // setImageUUID(await onSubmitPrompt(prompt));// Pass the search term back up
        if (selectedImage) {
            apiClient.createPost({ text, image_uuids: [selectedImage.uuid] })
            setText('');
            setSelectedImage(null);
            onClose();
        }
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };

    const handleImageSelect = (image: ImageModel) => {
        setSelectedImage(image);
        onSelectImageClose();
    }

    useEffect(() => {
        const fetchData = async () => {
            // const images = (await apiClient.getImages({})).data.images
            // setImagesToSelect(images)
        }
        fetchData();
    }, []);

    return (
        <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton size="lg" />
                <ModalBody >

                    {/* <Profile Image/> */}



                    <Flex alignItems="center" justifyContent="center" height={300} p={4}>
                        {selectedImage ? (

                            <Image
                                src={selectedImage?.url}
                                alt="Select image"
                                borderRadius={8}
                                height="full"
                                onClick={onSelectImageOpen}
                            />
                        ) : (
                            <Button leftIcon={<LinkIcon />} colorScheme='teal' variant='solid' onClick={onSelectImageOpen}>
                                Select Image
                            </Button>
                        )}
                    </Flex>

                    <SelectImageModal isOpen={isSelectImageOpen} onClose={onSelectImageClose} images={imageHistory} handleImageSelect={handleImageSelect} />

                    <form onSubmit={handleSubmit}>
                        <Flex direction="column" gap={8}>
                            <FormControl >
                                <Textarea
                                    value={text}
                                    onChange={handleTextChange}
                                    size="lg"
                                    placeholder="caption or something..."

                                    borderWidth={{ base: 3, sm: 1 }}

                                    as={ResizeTextarea}
                                    resize="none"
                                />

                            </FormControl>



                            <Flex width="full" justifyContent="flex-end">


                                <Button
                                    variant="outline"
                                    type="submit"
                                    borderWidth={{ base: 3, sm: 1 }}
                                >
                                    Post
                                </Button>
                            </Flex>
                        </Flex>
                    </form>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
};


interface SelectImageModalProps extends ModalProps {
    images: ImageModel[];
    handleImageSelect: (image: ImageModel) => void;
}

const SelectImageModal: React.FC<SelectImageModalProps> = ({ isOpen, onClose, images, handleImageSelect }) => {
    const { hasMore, getImageHistory } = useImagesContext();


    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {

        // We cast e.target to its expected type
        const target = e.target as HTMLDivElement;
        if (hasMore && target.scrollHeight - target.scrollTop <= target.clientHeight + 5
        ) {
            getImageHistory();
        }
    };


    return (
        <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="gray.900">
                <ModalHeader>Select Image</ModalHeader>
                <ModalCloseButton size="lg" />
                <ModalBody >
                    {/* <Wrap spacing={30} justify="space-evenly" maxHeight="80vh" overflowY="auto"> */}

                    <SimpleGrid spacing={{ base: 2, md: 8 }} maxHeight="70vh" overflowY="auto" minChildWidth={{ base: 100, md: 200 }} onScroll={handleScroll} >

                        {images.map((image) => (
                            <Box key={image.uuid} borderWidth={2} >
                                <Image

                                    src={image.url}
                                    alt={image.prompt}
                                    onClick={() => handleImageSelect(image)} />
                            </Box>
                        ))}

                    </SimpleGrid>

                    {/* </Wrap> */}

                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
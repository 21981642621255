import { Tabs, TabList, Tab, Image, Text, Flex, Box, TabPanel, TabPanels } from "@chakra-ui/react";
import { SignedIn, SignedOut, useUser } from "@clerk/clerk-react";
import { useApiClient } from "clients/ApiClient";
import { useState, useEffect } from "react";

import { Post, User } from 'clients/boutikbooty-api-codegen/api';

import Feed from "components/Feed";
import { PostComponent } from "components/posts/PostComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CreatePostButton } from "components/posts/CreatePostButton";

interface ProfilePageProps {
}

export const ProfilePage: React.FC<ProfilePageProps> = ({ }) => {
    const apiClient = useApiClient();
    const { user } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    const { userUuid } = useParams();
    const [viewedUser, setViewedUser] = useState<User | null>(null);

    const [userPosts, setUserPosts] = useState<Post[]>([]);
    const [likedPosts, setLikedPosts] = useState<Post[]>([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        setViewedUser(null);
        setUserPosts([]);
        setLikedPosts([]);

        const getUserPosts = async (tempUser2: typeof tempUser) => {
            if (tempUser2) {
                const posts = (await apiClient.getPosts({ author_uuid: tempUser2.uuid, parent_post_uuids: [] })).data.posts;

                setUserPosts(posts);
                setIsLoading(false);
            }
        };

        const getLikedPosts = async (tempUser2: typeof tempUser) => {
            if (tempUser2) {
                const posts = (await apiClient.getPosts({ liked_by_user_uuid: tempUser2.uuid, parent_post_uuids: [] })).data.posts;

                setLikedPosts(posts);
                setIsLoading(false);
            }
        }

        let tempUser = { uuid: user?.id || "", username: user?.username || "", image_url: user?.imageUrl || "" };

        const loadUser = async () => {
            if (userUuid) {
                if (userUuid === user?.id) {
                    navigate("/profile")
                } else {

                    const loadedUser = (await apiClient.getUser({ uuid: userUuid })).data.user;
                    if (loadedUser) {
                        tempUser = loadedUser
                        setViewedUser(tempUser);
                    } else {
                        navigate("/profile")
                    }
                }
            } else {
                setViewedUser(tempUser)
            }

            getUserPosts(tempUser);
            getLikedPosts(tempUser);
        }
        loadUser();



    }, [location.pathname]);

    return (
        <Flex justify="center">
            <SignedIn>
                <Box w={{ base: "100hw", sm: 500 }}>
                    {viewedUser ?

                        <Flex direction="row" justify="space-between" alignItems="center" p={4} gap={1}>
                            <Image
                                borderRadius='full'
                                boxSize='150px'
                                src={viewedUser.image_url}
                                alt='User pic'
                            />
                            {/* <Text>{user?.fullName}</Text> */}
                            <Text>{`@${viewedUser.username}`}</Text>
                        </Flex> : <></>
                    }

                    <Box>
                        <Tabs variant="enclosed">
                            <TabList justifyContent="center" >
                                <Tab>Posts</Tab>
                                <Tab>Liked</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel p={0}>
                                    <Feed
                                        posts={
                                            userPosts.map((post: Post) =>
                                                <PostComponent key={post.uuid} post={post} />
                                            )
                                        }
                                        isLoading={isLoading}
                                    />
                                </TabPanel>

                                <TabPanel p={0}>
                                    <Feed
                                        posts={
                                            likedPosts.map((post: Post) =>
                                                <PostComponent key={post.uuid} post={post} />
                                            )
                                        }
                                        isLoading={isLoading}
                                    />
                                </TabPanel>

                            </TabPanels>
                        </Tabs>

                    </Box>
                </Box >
                <CreatePostButton />

            </SignedIn>
            <SignedOut>
                Signin to view your profile
            </SignedOut>
        </Flex >
    );
}

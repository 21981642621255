import { useEffect } from 'react';

function useCannySDK() {
    useEffect(() => {
        const loadCannyScript = () => {
            const existingScript = document.getElementById('cannyScript');
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = 'https://canny.io/sdk.js';
                script.id = 'cannyScript';
                script.async = true;
                document.body.appendChild(script);
            }
        };

        loadCannyScript();
    }, []);
}

export default useCannySDK;

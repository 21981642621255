import { useAuth } from "@clerk/clerk-react";
import { DefaultApi } from "./boutikbooty-api-codegen/api";
import { Configuration } from "./boutikbooty-api-codegen/configuration";
import { createContext, useState, useEffect, useContext } from "react";


type GetTokenFuncType = () => Promise<string | null>;

class ApiClientHandler {
    private getToken: GetTokenFuncType;

    constructor(getTokenFunc: GetTokenFuncType) {
        this.getToken = getTokenFunc;
    }

    get(target: DefaultApi, prop: keyof DefaultApi, receiver: any) {
        const originalMethod = (target[prop] as any);
        if (typeof originalMethod === 'function') {
            return async (...args: any[]) => {
                const token = await this.getToken();
                const auth_header = token ? `Bearer ${token}` : null;
                return originalMethod.call(target, ...args, auth_header);
            }
        } else {
            return Reflect.get(target, prop, receiver);
        }
    }

    static HOSTNAME = '';
    static getTokenFunc: GetTokenFuncType | null = null;
    static GetTokenFuncSet: boolean = false;

    static setAuthTokenFunc(getToken: GetTokenFuncType) {
        this.getTokenFunc = getToken;
        this.GetTokenFuncSet = true;
    }

    static _getHostname() {
        if (this.HOSTNAME) {
            return this.HOSTNAME;
        }

        const env = process.env.REACT_APP_VERCEL_ENV;

        if (env === 'development') {
            this.HOSTNAME = 'http://hama.me:8000';
            // this.HOSTNAME = 'https://boutikbooty-api-git-dev-aeniac.vercel.app';
        }
        else if (env === 'preview') {
            this.HOSTNAME = 'https://boutikbooty-api-git-dev-aeniac.vercel.app';
        }
        else if (env === 'production') {
            this.HOSTNAME = 'https://boutikbooty-api.vercel.app';
        }

        return this.HOSTNAME;
    }
}

const ApiClientContext = createContext<DefaultApi | null>(null);

interface ApiClientProviderProps {
    children: React.ReactNode;
}

export const ApiClientProvider: React.FC<ApiClientProviderProps> = ({ children }) => {
    const { getToken } = useAuth();


    const handler = new ApiClientHandler(getToken);

    const [apiClient, setApiClient] = useState<DefaultApi>(new Proxy(new DefaultApi(new Configuration({ basePath: ApiClientHandler._getHostname() })), handler));

    // useEffect(() => {
    //     const apiClient = new DefaultApi(new Configuration({ basePath: ApiClientHandler._getHostname() }));
    //     const handler = new ApiClientHandler(getToken);
    //     const proxiedApiClient = new Proxy(apiClient, handler);

    //     setApiClient(proxiedApiClient);
    // }, [getToken]);

    return (
        <ApiClientContext.Provider value={apiClient}>
            {children}
        </ApiClientContext.Provider>
    );
}

export const useApiClient = () => {
    const context = useContext(ApiClientContext);
    if (context === null) {
        throw new Error("useApiClient must be used within an ApiClientProvider");
    }
    return context;
}



// const useApiClient = (): DefaultApi => {
//     const { getToken } = useAuth();

//     const [apiClient, setApiClient] = useState<DefaultApi>(
//         new DefaultApi(new Configuration({ basePath: ApiClientHandler._getHostname() }))
//     );

//     useEffect(() => {
//         const handler = new ApiClientHandler(getToken);
//         setApiClient(new Proxy(apiClient, handler));
//     }, [getToken]);

//     return apiClient;
// }


import { useEffect, useState, useRef, ReactNode } from 'react';
import { useApiClient } from 'clients/ApiClient';
import { Post } from 'clients/boutikbooty-api-codegen/api';
import React from 'react';

export function usePosts(orderByPopular: boolean = false) {
    const apiClient = useApiClient();

    const [posts, setPosts] = useState<Post[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const getPosts = async () => {
        try {
            const limit = 20;
            let offset = Math.floor(posts.length / limit);
            if (posts.length % limit !== 0) offset += 1;

            const fetchedPosts = (await apiClient.getPosts({ uuids: [], parent_post_uuids: [], offset: offset, order_by_popular: orderByPopular })).data.posts;

            if (fetchedPosts.length === 0) {
                setHasMore(false);
            }

            // setPosts(fetchedPosts);
            setPosts(prevPosts => [...prevPosts, ...fetchedPosts]);
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to get feed:', error);
        }
    };

    useEffect(() => {
        getPosts();
    }, []);


    return { posts, getPosts, isLoading, hasMore };
}


interface IUsePosts {
    posts: Post[];
    getPosts: () => Promise<void>;
    isLoading: boolean;
    hasMore: boolean;
}

const PostsContext = React.createContext<IUsePosts | undefined>(undefined);

const NewPostsContext = React.createContext<IUsePosts | undefined>(undefined);
const PopularPostsContext = React.createContext<IUsePosts | undefined>(undefined);

const useNewPosts = () => usePosts(false);
const usePopularPosts = () => usePosts(true);


export function useNewPostsContext() {
    const context = React.useContext(NewPostsContext);
    if (!context) {
        throw new Error(`useNewPostsContext must be used within a NewPostsContextProvider`);
    }
    return context;
}

export function usePopularPostsContext() {
    const context = React.useContext(PopularPostsContext);
    if (!context) {
        throw new Error(`usePopularPostsContext must be used within a PopularPostsContextProvider`);
    }
    return context;
}


interface PostsContextProviderProps {
    children: ReactNode;
}

export function NewPostsContextProvider({ children }: PostsContextProviderProps) {
    const newPostsState = useNewPosts();
    return <NewPostsContext.Provider value={newPostsState}>{children}</NewPostsContext.Provider>;
}

export function PopularPostsContextProvider({ children }: PostsContextProviderProps) {
    const popularPostsState = usePopularPosts();
    return <PopularPostsContext.Provider value={popularPostsState}>{children}</PopularPostsContext.Provider>;
}


// index.tsx

import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import { ColorModeScript } from '@chakra-ui/react';
import { theme } from 'ChakraUITheme';

const rootElement = document.getElementById('root');

if (rootElement) {
  ReactDOMClient.createRoot(rootElement).render(
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </>
  );
}

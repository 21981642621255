import React, { useState, useEffect } from 'react';
import { Button, Flex, Text } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { SignedIn } from "@clerk/clerk-react";
import { useCreatePostModalContext } from 'hooks/useCreatePostModal';



export const CreatePostButton: React.FC = () => {
    const [lastYPos, setLastYPos] = useState(0);
    const [shouldShowActions, setShouldShowActions] = useState(true);

    const { onCreatePostOpen } = useCreatePostModalContext();

    useEffect(() => {
        function handleScroll() {
            const yPos = window.scrollY;
            const isScrollingUp = yPos < lastYPos;

            setShouldShowActions(isScrollingUp);
            setLastYPos(yPos);

        }

        window.addEventListener("scroll", handleScroll, false);

        return () => {
            window.removeEventListener("scroll", handleScroll, false);
        };
    }, [lastYPos]);

    return (
        <SignedIn>
            {shouldShowActions &&
                <Button
                    onClick={onCreatePostOpen}
                    position="fixed" bottom={4} right={4}
                    borderRadius="full"
                    color="blue.500"
                    p={6}
                    bg="black"
                >
                    <Flex direction="column">
                        <Text>
                            <EditIcon />
                        </Text>
                        <Text fontSize="1em" fontWeight={1}>post</Text>
                    </Flex>
                </Button>
            }
        </SignedIn>
    );
}

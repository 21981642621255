import { Flex, Box, Image } from "@chakra-ui/react";
import { User } from "clients/boutikbooty-api-codegen";
import { useNavigate, useLocation, Link } from "react-router-dom";



export const AvatarHeader: React.FC<{ user: User }> = ({ user }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const openProfilePage = (event: React.MouseEvent) => {
        event.stopPropagation();
        const user_path = `/profile/${user.uuid}`

        if (location.pathname !== user_path) {
            navigate(user_path);
        }
    }

    return (
        <Flex alignItems="center" gap={1.5} width="fit-content">
            <Image onClick={openProfilePage} boxSize={5} borderRadius="full" src={user.image_url} />
            <Link to={`/profile/${user.uuid}`} color="whiteAlpha.700">@{user.username}</Link>
        </Flex>
    );
}
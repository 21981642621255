// web/src/components/Feed.tsx

import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

import InfiniteScroll from 'react-infinite-scroller';

interface FeedProps {
    posts: React.ReactNode[];
    isLoading?: boolean;
    loadMore?: () => void;
    hasMore?: boolean;
}

const Feed: React.FC<FeedProps> = ({ posts, isLoading = false, loadMore = () => null, hasMore = false }) => {



    return (


        <Flex direction="column" alignItems="center" >
            {isLoading ? (


                <Flex h="100vh" justifyContent="center" pt={100}>
                    <Spinner size="xl" boxSize={200} />
                </Flex>

            ) :
                (

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadMore}
                        hasMore={hasMore}
                        initialLoad={false}
                        threshold={1000}
                        loader={<div className="loader" key={0}>Loading ...</div>}
                    >
                        {posts}
                    </InfiniteScroll>

                )}
        </Flex>

    );
};

export default Feed;

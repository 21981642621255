import { Text, Image, Flex, Box, Button, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from "@chakra-ui/react";
import { AvatarHeader } from "../AvatarHeader";
import { Post } from "clients/boutikbooty-api-codegen";
import { useEffect, useRef, useState } from "react";
import { useApiClient } from "clients/ApiClient";
import { useLocation, useNavigate } from "react-router-dom";
import { ChatIcon, CheckIcon, InfoIcon, SearchIcon } from "@chakra-ui/icons";


export const PostComponent: React.FC<{ post: Post, isComment?: boolean }> = ({ post, isComment = false }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [showFullText, setShowFullText] = useState<boolean>(false);
    const [isTruncated, setIsTruncated] = useState<boolean>(false);

    const textRef = useRef<HTMLDivElement>(null);

    const toggleShowFullText = (event: React.MouseEvent) => {
        event.stopPropagation();
        setShowFullText(!showFullText);
    }

    const openPostPage = () => {
        const post_path = `/posts/${post.uuid}`

        if (location.pathname !== post_path) {
            navigate(post_path);
        }
    }

    const showImagePrompt = (event: React.MouseEvent) => {
        event.stopPropagation();

    }

    useEffect(() => {
        if (textRef.current) {
            const fullHeight = textRef.current.scrollHeight;
            const truncatedHeight = textRef.current.clientHeight;
            if (fullHeight > truncatedHeight) {
                setIsTruncated(true);
            }
        }
    }, [post.text]);

    return (
        <Box borderBottomWidth={2} borderLeftWidth={2} borderRightWidth={2} w={{ base: "100vw", sm: 500 }} bg="blackAlpha.500">

            <Box>
                {
                    post.images && post.images.length > 0 ?

                        <Image src={post.images[0].url} /> :
                        <></>
                }
            </Box>

            <Box px={3} pt={3} onClick={openPostPage}>
                <Flex alignItems="center" justify="space-between" >
                    <AvatarHeader user={post.author} />


                    {post.images.length > 0 ?
                        <Popover>
                            <PopoverTrigger>
                                <IconButton
                                    aria-label="Show image prompt"
                                    icon={<InfoIcon />}
                                    size="xs"
                                    bg="transparent"
                                    onClick={showImagePrompt}
                                />
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>image prompt:<br /> {post.images[0].prompt}</PopoverBody>
                            </PopoverContent>
                        </Popover>
                        : null
                    }

                </Flex>

                <Text ref={textRef} noOfLines={showFullText ? undefined : 4}>{post.text}</Text>

                {isTruncated ?
                    <Button variant="link" onClick={toggleShowFullText}>
                        {showFullText ? "show less" : "show more"}
                    </Button>
                    : null
                }
            </Box>

            <PostInteractButtons post={post} isComment={isComment} openPostPage={openPostPage} />

        </Box>
    );
};


export const PostInteractButtons: React.FC<{ post: Post, isComment?: boolean, openPostPage: () => void }> = ({ post, isComment = false, openPostPage }) => {
    const apiClient = useApiClient();

    const [callerLiked, setCallerLiked] = useState<boolean>(post.caller_liked);
    const [likes, setLikes] = useState<number>(post.likes);

    const toggleLike = async () => {
        const tempLike = !callerLiked;
        setCallerLiked(!callerLiked);

        if (tempLike) {
            setLikes(likes + 1);
            await apiClient.likePost({ post_uuid: post.uuid });
        } else {
            setLikes(likes - 1);
            await apiClient.unlikePost({ post_uuid: post.uuid });
        }
    };

    return (
        <>
            {
                !isComment ?
                    < Flex px={2} borderTopWidth={1} alignItems="center" >
                        <Button variant='ghost' h={8} textAlign="center" borderRadius={0}
                            onClick={toggleLike}>
                            <Text px={1}>{likes}</Text>
                            <Text color="whiteAlpha.700">Likes</Text>
                        </Button>

                        <Button variant='ghost' h={8} textAlign="center" borderRadius={0} onClick={openPostPage}>
                            <Text px={1}>{post.num_comments}</Text>
                            <Text color="whiteAlpha.700">Comments</Text>
                        </Button>

                        {/* <Button variant='ghost' h={8} textAlign="center" borderRadius={0}>
                            <Text px={1}>{post.flags}</Text>
                            <Text color="whiteAlpha.700">Flags</Text>
                        </Button> */}
                    </Flex >

                    :

                    < Flex pl={5} pb={1} alignItems="center" width="full" gap={4} >
                        <Button variant='ghost' h={8} textAlign="center" borderRadius={0}
                            onClick={toggleLike}>
                            <Text px={1}>{likes}</Text>
                            <Text color="whiteAlpha.700">
                                <CheckIcon />
                            </Text>
                        </Button>

                        <Button variant='ghost' h={8} textAlign="center" borderRadius={0} gap={1} onClick={openPostPage}>
                            <Text px={1}>{post.num_comments}</Text>
                            <Text color="whiteAlpha.700">
                                <ChatIcon />
                            </Text>
                        </Button>

                    </Flex >

            }
        </>
    )

}

import { useDisclosure } from "@chakra-ui/react";

export const useSignUpModal = () => {
    const { isOpen: isSignUpOpen, onOpen: onSignUpOpen, onClose: onSignUpClose } = useDisclosure();


    return { isSignUpOpen, onSignUpOpen, onSignUpClose };
};

export const useLoginModal = () => {
    const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();



    return { isLoginOpen, onLoginOpen, onLoginClose };
};





export const useSearchDropdown = () => {
    const { isOpen: isSearchDropdownOpen, onOpen: onSearchDropdownOpen, onClose: onSearchDropdownClose } = useDisclosure();

    return { isSearchDropdownOpen, onSearchDropdownOpen, onSearchDropdownClose };
}



import { useDisclosure } from "@chakra-ui/react";
import { createContext, useContext, ReactNode } from 'react';

export const useCreatePostModal = () => {
    const { isOpen: isCreatePostOpen, onOpen: onCreatePostOpen, onClose: onCreatePostClose } = useDisclosure();



    return { isCreatePostOpen, onCreatePostOpen, onCreatePostClose };
};



type CreatePostModalContextType = {
    isCreatePostOpen: boolean;
    onCreatePostOpen: () => void;
    onCreatePostClose: () => void;
};

const CreatePostModalContext = createContext<CreatePostModalContextType | undefined>(undefined);


export const CreatePostModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { isOpen: isCreatePostOpen, onOpen: onCreatePostOpen, onClose: onCreatePostClose } = useDisclosure();

    return (
        <CreatePostModalContext.Provider value={{ isCreatePostOpen, onCreatePostOpen, onCreatePostClose }}>
            {children}
        </CreatePostModalContext.Provider>
    );
};

export const useCreatePostModalContext = (): CreatePostModalContextType => {
    const context = useContext(CreatePostModalContext);
    if (!context) {
        throw new Error('useCreatePostModalContext must be used within a CreatePostModalProvider');
    }
    return context;
};




// web/src/HomePage.tsx
import React, { } from 'react';
import Feed from 'components/Feed';
import { Tabs, TabList, Tab, Flex, Box, TabPanel, TabPanels } from "@chakra-ui/react";
import { Post } from 'clients/boutikbooty-api-codegen/api';
import { PostComponent } from 'components/posts/PostComponent';
import { useNewPostsContext, usePopularPostsContext } from 'hooks/usePosts';
import { CreatePostButton } from 'components/posts/CreatePostButton';

const HomePage: React.FC = () => {

    const { posts: newPosts, isLoading: isNewLoading, getPosts: getNewPosts, hasMore: hasNewMore } = useNewPostsContext();
    const { posts: popularPosts, isLoading: isPopularLoading, getPosts: getPopularPosts, hasMore: hasPopularMore } = usePopularPostsContext();

    return (
        <Flex justify="center">
            <Box w={{ base: "100vw", sm: 500 }}>

                <Tabs variant="enclosed" >
                    <TabList justifyContent="center">
                        <Tab>New</Tab>
                        <Tab>Popular</Tab>

                    </TabList>
                    <TabPanels>
                        <TabPanel p={0}>
                            <Feed
                                posts={[
                                    // <AdsterraBlockAd key="adsterra" />,
                                    ...newPosts.map((post: Post) => <PostComponent key={post.uuid} post={post} />),
                                ]}
                                isLoading={isNewLoading}
                                loadMore={getNewPosts}
                                hasMore={hasNewMore}
                            />
                        </TabPanel>

                        <TabPanel p={0}>
                            <Feed
                                posts={popularPosts.map((post: Post) => <PostComponent key={post.uuid} post={post} />)}
                                isLoading={isPopularLoading}
                                loadMore={getPopularPosts}
                                hasMore={hasPopularMore}
                            />
                        </TabPanel>

                    </TabPanels>
                </Tabs>

                <CreatePostButton />

            </Box>
        </Flex>
    );
};

export default HomePage;

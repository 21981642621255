import { useApiClient } from "clients/ApiClient";
import { Post } from "clients/boutikbooty-api-codegen";
import Feed from "components/Feed";
import { PostComponent } from "components/posts/PostComponent";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { WriteComment } from "components/posts/WriteComment";
import { Box, Flex, SkeletonCircle, SkeletonText, Spinner } from "@chakra-ui/react";
import { SignedIn } from "@clerk/clerk-react";

export const PostPage: React.FC = () => {
    const apiClient = useApiClient();
    const location = useLocation();

    const { postUuid } = useParams();
    const [post, setPost] = useState<Post | null>(null);
    const [comments, setComments] = useState<Post[]>([]);
    const [localCacheComments, setLocalCacheComments] = useState<Post[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const addComment = (post: Post) => {
        setLocalCacheComments([post, ...localCacheComments])
    }

    useEffect(() => {
        setPost(null)
        setComments([])
        setLocalCacheComments([])

        if (postUuid) {
            setIsLoading(true)

            const loadPost = async () => {
                const loadedPost = (await apiClient.getPost({ uuid: postUuid })).data.post
                if (loadedPost) {
                    setPost(loadedPost)
                } else {
                    setPost(null)
                }
            }
            loadPost()

            const loadComments = async () => {
                const loadedComments = (await apiClient.getPosts({ parent_post_uuids: [postUuid] })).data.posts
                setComments(loadedComments)
            }
            loadComments()
        }

        setIsLoading(false)

    }, [location]);


    return (
        <div>
            {
                post && !isLoading ? <Feed posts={[
                    <PostComponent key={post.uuid} post={post} />,

                    <SignedIn  >
                        <WriteComment addComment={addComment} parent_post={post} key="write_comment" />
                    </SignedIn>,

                    ...localCacheComments.map((post: Post) => (
                        <PostComponent isComment={true} key={post.uuid} post={post} />
                    )),

                    ...comments.map((post: Post) => (
                        <PostComponent isComment={true} key={post.uuid} post={post} />
                    )),
                ]}
                /> : (


                    <Flex h="full" alignItems="center" justifyContent="center">
                        <Box padding='6' w="500px" boxShadow='md' bg='transparent' h="full">
                            <SkeletonText mt='4' noOfLines={30} spacing='4' skeletonHeight='2' isLoaded={isLoading} fadeDuration={4} />
                        </Box>
                    </Flex>

                )
            }
        </div>


    )
}


// <Comment authorUsername={""} text={""} likes={0} /> 


// <PostComponent isComment={true} key="1" post={{
//     uuid: "1",
//     author: { uuid: "1", username: "hamash", image_url: "" },
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//     images: [],
//     num_comments: 0,
//     likes: 0,
//     caller_liked: false,
//     flags: 0

// }} />,
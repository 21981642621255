// web/src/HomePage.tsx

import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";



const RootLayout: React.FC = ({ }) => {



    return (
        <Box >
            <Outlet />
        </Box>
    );
};

export default RootLayout;

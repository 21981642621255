import { Card, CardBody, Text, Image, Flex, Box, FormControl, Textarea, CardFooter, Button, Input } from "@chakra-ui/react";
import { AvatarHeader } from "../AvatarHeader";
import { useUser } from "@clerk/clerk-react";
import ResizeTextarea from "react-textarea-autosize";
import { useState } from "react";
import { useApiClient } from "clients/ApiClient";
import { Post } from "clients/boutikbooty-api-codegen";




export const WriteComment: React.FC<{ parent_post: Post, addComment: (post: Post) => void }> = ({ parent_post, addComment }) => {
    const apiClient = useApiClient();
    const { user } = useUser();

    const [reply, setReply] = useState('');

    const [isTyping, setIsTyping] = useState<boolean>(false);

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReply(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();  // prevent the form from submitting
        setReply('')

        const comment_uuid = (await apiClient.createPost({ text: reply, parent_post_uuid: parent_post.uuid })).data.uuid

        if (comment_uuid) {
            const comment = {
                uuid: comment_uuid,
                text: reply,
                author: {
                    uuid: user?.id || "",
                    username: user?.username || "",
                    image_url: user?.imageUrl || ""
                },
                images: [],
                likes: 0,
                flags: 0,
                caller_liked: false,
                num_comments: 0,
            }
            // console.log(comment)
            addComment(comment);
        }
    };


    return (
        <Box borderBottomWidth={2} borderLeftWidth={2} borderRightWidth={2} w={{ base: "100hw", sm: 500 }} bg="blackAlpha.500">

            <Box px={3} pt={3} >
                <AvatarHeader user={{ uuid: user?.id || "", username: user?.username || "", image_url: user?.imageUrl || "" }} />

                <Box pb={2} pt={2} >

                    <form onSubmit={handleSubmit} >
                        <Flex direction="column" gap={1}>
                            <FormControl >
                                <Box >
                                    <Textarea
                                        size="md"
                                        placeholder="reply..."
                                        minH="2em"
                                        value={reply}
                                        onChange={handleTextChange}
                                        resize="none"
                                        focusBorderColor="transparent"
                                        as={ResizeTextarea}
                                    />
                                    {/**/}
                                    {/* <Input
                                    focusBorderColor="transparent"
                                    textOverflow="unset"
                                    h="2em"
                                    variant="outline"
                                    borderWidth={1}
                                    placeholder="reply..."
                                /> */}
                                </Box>
                            </FormControl>

                            {reply ?
                                <Flex justify="right" width="full">
                                    <Button
                                        size="xs"
                                        variant="outline"
                                        type="submit"

                                    >
                                        reply
                                    </Button>
                                </Flex>
                                :
                                <></>
                            }
                        </Flex>
                    </form>
                </Box>
            </Box>

        </Box>
    );
};


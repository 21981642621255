// web/src/components/Header.tsx

import { ChatIcon, HamburgerIcon, LinkIcon, SpinnerIcon, UpDownIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, FormControl, Input, Tab, TabList, Tabs, Avatar, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure, Menu, MenuItem, MenuList, MenuButton, IconButton, Text, useBreakpointValue } from '@chakra-ui/react';
import { SignedIn, SignedOut, UserButton, useAuth } from '@clerk/clerk-react';
import { useSearchContext } from 'hooks/useSearch';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface HeaderProps {
    onOpenModal: () => void;
}

const Header: React.FC<HeaderProps> = ({ onOpenModal }) => {
    const [prompt, setPrompt] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

    const { search } = useSearchContext();
    const navigate = useNavigate();
    const location = useLocation();

    const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrompt(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();  // prevent the form from submitting
        navigate('/search');

        await search(prompt)
        inputRef.current?.blur();
    };

    const goToFeedbackPage = () => {
        navigate('/feedback')
    }

    useEffect(() => {
        if (location.pathname !== '/search') {
            inputRef.current?.blur();
            setPrompt('')
        }
    }, [location.pathname])

    return (
        <Flex flexWrap="wrap" alignItems="center" bg="gray.900" px={4} justify="space-between" py={{ base: 2, sm: 0 }} gap={3} >
            <Link to="/" >
                BoutikBooty
            </Link>


            {/* search bar */}
            <Flex flex="1" maxWidth="50%" justify="center" >
                <form onSubmit={handleSubmit}>
                    <FormControl >
                        <Box position="relative">
                            <Input
                                ref={inputRef}
                                bg="gray.800"
                                focusBorderColor="transparent"
                                h="2em"
                                variant="outline"
                                borderWidth={{ base: 2, sm: 1 }}
                                value={prompt}
                                onChange={handleSearchChange}
                            />
                            <SearchIcon
                                position="absolute"
                                right="0.5rem"
                                top="50%"
                                transform="translateY(-50%)" // Center the icon vertically
                                color="gray.400"
                            />
                        </Box>
                    </FormControl>
                </form>

            </Flex>


            {useBreakpointValue({ base: false, sm: true }, { ssr: false }) ?
                <HeaderTabs onOpen={onOpen} isOpen={isOpen} onToggle={onToggle} />
                : null
            }

            <SignedOut>
                <Popover
                    isOpen={isOpen}
                    onClose={onClose}
                    defaultIsOpen={false}
                    openDelay={500}
                    closeDelay={500}
                >
                    <PopoverTrigger>
                        <Avatar size="sm" onClick={onOpenModal} />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>Signin to create images and view profile</PopoverBody>
                    </PopoverContent>
                </Popover>
            </SignedOut>

            <SignedIn  >
                <Flex gap={3}>


                    <Button
                        bg="transparent"
                        onClick={goToFeedbackPage}
                        display={{ base: "none", sm: "block" }}
                    >
                        <Flex direction="column">
                            <Text >
                                <ChatIcon />
                            </Text>
                            <Text fontSize="1em" fontWeight={1} >feedback</Text>
                        </Flex>
                    </Button>

                    <UserButton />


                </Flex>
            </SignedIn>

            <Menu>
                <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<HamburgerIcon />}
                    variant='outline'
                    borderWidth={2}
                    display={{ base: "block", sm: "none" }}
                />
                <MenuList>
                    <MenuItem
                        icon={<LinkIcon />}
                        onClick={() => { navigate('/create') }}
                    >
                        Create
                    </MenuItem>
                    <MenuItem
                        icon={<UpDownIcon />}
                        onClick={() => { navigate('/') }}
                    >
                        Feed
                    </MenuItem>
                    <MenuItem
                        icon={<SpinnerIcon />}
                        onClick={() => { navigate('/profile') }}
                    >
                        Profile
                    </MenuItem>
                    <MenuItem
                        icon={<ChatIcon />}
                        onClick={() => { navigate('/feedback') }}
                    >
                        Feedback
                    </MenuItem>
                </MenuList>
            </Menu>




        </Flex>
    );
};

export default Header;


interface HeaderTabsProps {
    onOpen: () => void;
    isOpen: boolean;
    onToggle: () => void;
}

const tabIndices: Record<string, number> = {
    "/create": 0,
    "/": 1,
    "/profile": 2,

};

const HeaderTabs: React.FC<HeaderTabsProps> = ({ onOpen, isOpen, onToggle }) => {
    const { isSignedIn } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

    useEffect(() => {
        const index = tabIndices[location.pathname];

        if (index !== undefined) {
            setCurrentTabIndex(index)
        } else {
            setCurrentTabIndex(-1)
        }

    }, [location.pathname])

    const handleTabClick = (index: number) => {
        const go_to_path = Object.keys(tabIndices).find(key => tabIndices[key] === index);

        if (go_to_path && (isSignedIn || go_to_path === "/")) {
            navigate(go_to_path);
        }
    };

    const showSigninPopover = () => {
        if (!isSignedIn) {
            onToggle();
        }
    }

    return (
        <Tabs index={currentTabIndex} onChange={handleTabClick}  >
            <TabList>

                <Tab onClick={showSigninPopover}>Create</Tab>
                <Tab>Feed</Tab>
                <Tab onClick={showSigninPopover}>Profile</Tab>

            </TabList>
        </Tabs>
    );
}



// Search bar drop down stuff
// const { isSearchDropdownOpen, onSearchDropdownOpen, onSearchDropdownClose } = useSearchDropdown()

{/*search results*/ }
{/* <Box position="absolute" bottom="0" left="0" height="0px" width="0px">
                                <Menu isOpen={isSearchDropdownOpen} onClose={onSearchDropdownClose} placement="bottom" matchWidth>
                                    <MenuButton as={() => <></>} />
                                    <MenuList>
                                        <MenuGroup title='Posts'>
                                            {searchPostResults.map(post => (
                                                <MenuItem>
                                                    <Image src={post.images[0].url} alt={post.text} />
                                                </MenuItem>
                                            ))}
                                        </MenuGroup>

                                        <SignedIn>

                                            <MenuGroup title='Your Posts'>
                                            </MenuGroup>

                                            <MenuGroup title='Your Images'>
                                                {searchImageResults.map(image => (
                                                    <MenuItem>
                                                        <Image src={image.url} alt={image.prompt} />
                                                    </MenuItem>
                                                ))}

                                            </MenuGroup>
                                        </SignedIn>
                                         <MenuGroup title='Comments'>
                                        </MenuGroup> 
                                        <SignedIn>
                                            <MenuGroup title='Create'>
                                                <MenuItem >prompt</MenuItem>
                                            </MenuGroup>
                                        </SignedIn>

                                    </MenuList>
                                </Menu>
                            </Box> */}
import { CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { Card, CardBody, Text, Image, Progress, Box, Spinner, Flex, IconButton } from "@chakra-ui/react";
import { Image as ImageModel } from 'clients/boutikbooty-api-codegen/api';
import { useImagesContext } from "hooks/useImages";



export const ImageInProgressCard: React.FC<{ image: ImageModel }> = ({ image }) => {
    return (
        <Card variant="outline" bg="gray.700" borderRadius={0} borderRightColor="transparent" borderColor="transparent">
            <CardBody>
                <Text>{image.prompt}</Text>
                <ImageInProgressImage image={image} />
            </CardBody>
        </Card>
    );
};

export const ImageInProgressImage: React.FC<{ image: ImageModel, borderRadius?: number }> = ({ image, borderRadius = 0 }) => {
    const { deleteImage } = useImagesContext();

    return (
        <>
            {image.url ? (

                <Box
                    position="relative"
                    display="inline-block"
                    _hover={{ ".overlay-icon": { opacity: 1 } }}
                >
                    <Image borderRadius={borderRadius}
                        src={image.url}
                        alt="Created image"
                    />
                    {/* <IconButton
                        aria-label="Like"
                        icon={<CheckIcon />}
                        position="absolute"
                        bottom="5px"
                        left="5px"
                        opacity="0"
                        transition="opacity 0.2s"
                        className="overlay-icon"
                    /> */}
                    <IconButton
                        aria-label="Delete"
                        icon={<DeleteIcon />}
                        position="absolute"
                        bottom="5px"
                        right="5px"
                        opacity="0"
                        transition="opacity 0.2s"
                        className="overlay-icon"
                        onClick={() => deleteImage(image.uuid)}
                    />
                </Box>
            ) : image.status === "starting" ? (
                <Flex h="full" direction="column" alignItems="center" justify="space-around" >
                    <Spinner size="xl" />
                    <Text color="whiteAlpha.700">please wait a minute for AI model bootup...</Text>
                </Flex>
            ) : (
                <Box >
                    <Image
                        src="https://via.placeholder.com/300.png?text=Creating..."
                        alt="Creating..."
                    />
                    <Progress isAnimated hasStripe value={image.progress_percent} />
                </Box>
            )


            }
        </>
    )
}


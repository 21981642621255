import { Flex, FormControl, Textarea, Box, Button, Divider, Heading } from "@chakra-ui/react";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import { useEffect, useRef, useState } from "react";
import { Image as ImageModel } from 'clients/boutikbooty-api-codegen/api';
import { ImageInProgressImage } from "components/ImageInProgress";
import { useImagesContext } from "hooks/useImages";
import InfiniteScroll from "react-infinite-scroller";
import ResizeTextarea from "react-textarea-autosize";
import { CreatePostButton } from "components/posts/CreatePostButton";



export const CreatePage: React.FC = () => {
    const { imagesInProgress, createImage, imageHistory, getImageHistory, hasMore } = useImagesContext();
    const [prompt, setPrompt] = useState('');
    const [latestImage, setLatestImage] = useState<ImageModel | undefined>(undefined);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [offset, setOffset] = useState(0); // This state will keep track of the current offset


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
        event.preventDefault();  // prevent the form from submitting
        setPrompt('')
        textAreaRef.current?.blur();  // clear or unfocus from text area, cant remember
        setLatestImage(await createImage(prompt));
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPrompt(event.target.value);
    };

    const handleEnterPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(event);
        }
    }

    useEffect(() => {
        if (latestImage) {
            const image = imagesInProgress.find(image => image.uuid === latestImage.uuid);

            if (image && image.url !== latestImage.url) {
                setLatestImage(image);
            }
        }
    }, [imagesInProgress]);



    return (
        <>
            <SignedIn>
                {/* page */}
                <Flex
                    alignItems="center" direction="column" px={20} pb={20}
                    pt={{ base: 0, sm: 20 }}
                >

                    <Flex
                        direction="column" alignItems="center" borderWidth={1}
                        w={{ base: "100vw", sm: "70vw" }} minH="50vh" borderRadius={8}
                    >

                        {/*Create Image*/}
                        <Flex
                            direction="column" alignItems="center"
                            py={10} gap={4}
                        >

                            <form onSubmit={handleSubmit}>
                                <FormControl >
                                    <Textarea
                                        ref={textAreaRef}
                                        onKeyDown={handleEnterPress}
                                        value={prompt}
                                        onChange={handleTextChange} size="lg"

                                        width={{ base: "90vw", sm: "50vw" }}
                                        borderWidth={{ base: 3, sm: 1 }}

                                        placeholder="type prompt..."

                                        as={ResizeTextarea}
                                        resize="none"
                                    />
                                </FormControl>

                                <Button
                                    variant="outline"
                                    type="submit"
                                    width="full"
                                    mt={4}
                                    borderWidth={{ base: 3, sm: 1 }}
                                >
                                    Create
                                </Button>
                            </form>
                        </Flex>



                        {/*Queue*/}
                        <CreatePagePanel title="Queue" images={imagesInProgress.slice().reverse()} />

                        {/* History and possibly favourites at some point - look at dalle history */}
                        <>
                            {
                                imageHistory.length > 0
                                    ? <Box w="full" >
                                        <Divider />

                                        <Box w="full" px={{ base: 1, sm: 8 }} py={4}>
                                            <Heading>{"History"}</Heading>

                                            <InfiniteScroll
                                                pageStart={0}
                                                loadMore={getImageHistory}
                                                hasMore={hasMore}
                                                initialLoad={false}
                                                threshold={1000}
                                                loader={<div className="loader" key={0}>Loading ...</div>}
                                            >

                                                {imageHistory.map(image => (
                                                    <Flex key={image.uuid} py={4}
                                                        w="full"
                                                        alignItems="center"
                                                        h="contain"

                                                        justify="space-between"
                                                        gap={2}
                                                    >


                                                        <ImageInProgressImage image={image} borderRadius={8} />


                                                        <Box
                                                            w="full"
                                                            alignItems={"center"}
                                                            h="full"
                                                            textAlign="center"
                                                        >{image.prompt}
                                                        </Box>
                                                    </Flex>

                                                ))}
                                            </InfiniteScroll>


                                        </Box>
                                    </Box>
                                    : null
                            }
                        </>



                    </Flex >
                </Flex>

                <CreatePostButton />
            </SignedIn>

            <SignedOut>
                Signin to create images
            </SignedOut>
        </>
    );
}


const CreatePagePanel: React.FC<{ title: string, images: ImageModel[] }> = ({ title, images }) => {

    return (
        <>
            {
                images.length > 0
                    ? <Box w="full" >
                        <Divider />

                        <Box w="full" px={{ base: 1, sm: 8 }} py={4}>
                            <Heading>{title}</Heading>

                            {images.map(image => (
                                <Flex key={image.uuid} py={4}
                                    w="full"
                                    alignItems="center"
                                    h="contain"

                                    justify="space-between"
                                    gap={2}
                                >



                                    <ImageInProgressImage image={image} borderRadius={8} />


                                    <Box
                                        w="full"
                                        alignItems={"center"}
                                        h="full"
                                        textAlign="center"
                                    >{image.prompt}
                                    </Box>
                                </Flex>

                            ))}
                        </Box>
                    </Box>
                    : null
            }
        </>
    );
}


import { useEffect, useState, useRef, ReactNode } from 'react';
import { useApiClient } from 'clients/ApiClient';
import { Image } from 'clients/boutikbooty-api-codegen/api';
import React from 'react';

export function useImages() {
    const apiClient = useApiClient();

    const [imagesInProgress, setImagesInProgress] = useState<Image[]>([]);
    const imagesInProgressRef = useRef(imagesInProgress);
    imagesInProgressRef.current = imagesInProgress;

    const [imageHistory, setImageHistory] = useState<Image[]>([]);
    const [hasMore, setHasMore] = useState(true);

    const [isLoading, setIsLoading] = useState(false);


    const createImage = async (searchTerm: string): Promise<Image> => {
        try {
            const image = (await apiClient.createImageV2({ prompt: searchTerm })).data;
            setImagesInProgress([...imagesInProgressRef.current, image]);
            return image;
        } catch (error) {
            console.error('Failed to generate image: ', error);
            throw error;
        }
    };

    const deleteImage = async (uuid: string) => {
        try {

            setImagesInProgress(prevImages => prevImages.filter(image => image.uuid !== uuid));
            setImageHistory(prevHistory => prevHistory.filter(image => image.uuid !== uuid));
            await apiClient.deleteImage({ uuid });

        } catch (error) {
            console.error('Failed to generate image: ', error);
            throw error;
        }
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const updateImageUrls = async () => {
            const uuids = imagesInProgressRef.current.filter(image => image.url === null).map(image => image.uuid);

            if (uuids.length > 0) {
                try {
                    const updatedImagesMap = (await apiClient.getImageProgress({ uuids })).data.data;

                    setImagesInProgress(imagesInProgressRef.current.map(image => {
                        const updatedImage = updatedImagesMap[image.uuid];
                        return updatedImage ? { ...image, ...updatedImage } : image;
                    }));
                } catch (error) {
                    console.error('Error fetching image URLs:', error);
                }
            } else {
                if (intervalId) clearInterval(intervalId);
            }
        };

        updateImageUrls();
        intervalId = setInterval(updateImageUrls, 1 * 1000); // every second

        return () => clearInterval(intervalId);
    }, [imagesInProgress.length]);



    const getImageHistory = async () => {

        try {
            let offset = Math.floor(imageHistory.length / 20);
            if (imageHistory.length % 20 !== 0) { offset += 1; }

            if (!isLoading) {
                setIsLoading(true);

                const fetchedImages = (await apiClient.getImages({ offset: offset })).data.images;
                if (fetchedImages.length === 0) {
                    setHasMore(false);
                }

                setImageHistory(prevImages => [...prevImages, ...fetchedImages]);

                setIsLoading(false);
            }

        } catch (error) {
            console.error('Failed to retrieve image history: ', error);
            throw error;
        }
    };

    useEffect(() => {
        getImageHistory()
    }, []);

    return { imagesInProgress, createImage, deleteImage, imageHistory, getImageHistory, hasMore };
}


interface ICreateImage {
    imagesInProgress: Image[];
    createImage: (searchTerm: string) => Promise<Image>;
    deleteImage: (uuid: string) => Promise<void>;
    imageHistory: Image[];
    getImageHistory: () => Promise<void>;
    hasMore: boolean;
}

const ImagesContext = React.createContext<ICreateImage | undefined>(undefined);

export function useImagesContext() {
    const context = React.useContext(ImagesContext);
    if (!context) {
        throw new Error(`useCreateImageContext must be used within a CreateImageProvider`)
    }
    return context;
}

interface ImagesContextProviderProps {
    children: ReactNode;
}

export function ImagesContextProvider({ children }: ImagesContextProviderProps) {
    const useImagesState = useImages();
    return <ImagesContext.Provider value={useImagesState}>{children}</ImagesContext.Provider>;
}

import { Box } from "@chakra-ui/react";
import CannyFeedbackWidget from "components/third_party/CannyFeedbackWidget";


function FeedbackPage() {

    return (
        <Box
            px={{ base: 0, sm: 20 }}
            pt={{ base: 0, sm: 10 }}
        >
            <Box
                borderRadius={8}
                borderWidth={1}
                p={{ base: 0, sm: 4 }}
            >
                <CannyFeedbackWidget />
            </Box>
        </Box>
    )
}

export default FeedbackPage;

// web/src/App.tsx
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { ClerkProvider } from "@clerk/clerk-react";
import AppManager from 'AppManager';
import { ApiClientProvider } from 'clients/ApiClient';
import { theme } from 'ChakraUITheme';
import { dark } from '@clerk/themes';
import { ImagesContextProvider } from 'hooks/useImages';
import { SearchContextProvider } from 'hooks/useSearch';
import { NewPostsContextProvider, PopularPostsContextProvider } from 'hooks/usePosts';
import { CreatePostModalProvider } from 'hooks/useCreatePostModal';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {

  return (
    <ChakraProvider theme={theme}>
      <ClerkProvider publishableKey={clerkPubKey} appearance={{ baseTheme: dark }}>
        <ApiClientProvider>

          <Router>

            <ImagesContextProvider>

              <NewPostsContextProvider>
                <PopularPostsContextProvider>

                  <SearchContextProvider>

                    <CreatePostModalProvider>

                      <AppManager />

                    </CreatePostModalProvider>


                  </SearchContextProvider>
                </PopularPostsContextProvider>
              </NewPostsContextProvider>
            </ImagesContextProvider>
          </Router>
        </ApiClientProvider>
      </ClerkProvider>
    </ChakraProvider>
  );
}

export default App;

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody } from "@chakra-ui/react";
import { SignIn, SignUp } from "@clerk/clerk-react";
import { ModalProps } from "interfaces/ModalProps";

interface LoginSignUpModalsWrapperProps {
    isLoginOpen: boolean;
    onLoginOpen: () => void;
    onLoginClose: () => void;
    isSignUpOpen: boolean;
    onSignUpOpen: () => void;
    onSignUpClose: () => void;
}

const LoginSignUpModalsWrapper: React.FC<LoginSignUpModalsWrapperProps> = ({ isLoginOpen, onLoginOpen, onLoginClose, isSignUpOpen, onSignUpOpen, onSignUpClose }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash.startsWith('#signup')) {
            onLoginClose();
            onSignUpOpen();
        } else if (location.hash.startsWith('#login')) {
            onSignUpClose();
            onLoginOpen();
        }


        else if (location.hash === '') {
            onSignUpClose();
        }
    }, [location.hash, onLoginClose, onSignUpOpen, onSignUpClose, onLoginOpen]);

    const onLoginCloseWithUrlRefresh = () => {
        navigate(window.location.pathname);
        onLoginClose();
    }

    const onSignUpCloseWithUrlRefresh = () => {
        navigate(window.location.pathname);
        onSignUpClose();
    }

    return (
        <>
            <LoginModal
                isOpen={isLoginOpen}
                onClose={onLoginCloseWithUrlRefresh}
            />
            <SignUpModal
                isOpen={isSignUpOpen}
                onClose={onSignUpCloseWithUrlRefresh}
            />
        </>
    )
}

export default LoginSignUpModalsWrapper;

const LoginModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    const location = useLocation();

    return (
        <>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <SignIn signUpUrl={location.pathname + '#signup'} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

const SignUpModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    const location = useLocation();

    return (
        <>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <SignUp
                            signInUrl={location.pathname + '#login'}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
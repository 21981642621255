import { defineStyleConfig, extendTheme, type ThemeConfig } from "@chakra-ui/react";

// const Button = defineStyleConfig({
//     baseStyle: {
//         bg: "whiteAlpha.200"
//     },
// })

const config: ThemeConfig = {
    initialColorMode: 'dark', // 'dark' | 'light'
    useSystemColorMode: false,

}

export const theme = extendTheme({
    config: config,
    // components: {
    //     Button: {
    //         baseStyle: {
    //             backgroundColor: "whiteAlpha.200",
    //         }
    //     }
    // }
});


import { useEffect, useState } from 'react';
import { useApiClient } from 'clients/ApiClient';
import { Image as ImageModel, Post, Post as PostModel } from 'clients/boutikbooty-api-codegen';
import React, { ReactNode, createContext, useContext } from 'react';

export function useSearch() {
    const apiClient = useApiClient();

    const [searchPostResults, setSearchPostResults] = useState<PostModel[]>([])
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));


    const search = async (searchTerm: string) => {
        setIsSearchLoading(true)
        setSearchPostResults([])

        let searchResults: Post[] = []
        try {
            searchResults = (await apiClient.search({ search_term: searchTerm })).data.posts
        } catch (error) {
            console.error('Failed to search:', error);
        }

        setSearchPostResults(searchResults)
        setIsSearchLoading(false)
    };

    return { searchPostResults, search, isSearchLoading };
}

interface ISearch {
    searchPostResults: PostModel[];
    search: (searchTerm: string) => Promise<void>;
    isSearchLoading: boolean;
}

const SearchContext = createContext<ISearch | undefined>(undefined);

export function useSearchContext() {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error(`useSearchContext must be used within a SearchProvider`)
    }
    return context;
}

interface SearchContextProviderProps {
    children: ReactNode;
}

export function SearchContextProvider({ children }: SearchContextProviderProps) {
    const searchState = useSearch();
    return <SearchContext.Provider value={searchState}>{children}</SearchContext.Provider>;
}

import { Box, Flex, Image } from "@chakra-ui/react";
import { useApiClient } from "clients/ApiClient";
import { Image as ImageModel, Post, Post as PostModel } from 'clients/boutikbooty-api-codegen';
import Feed from "components/Feed";
import { ImageInProgressImage } from "components/ImageInProgress";
import { PostComponent } from "components/posts/PostComponent";
import { useSearchContext } from "hooks/useSearch";
import { useEffect, useState } from "react";

export function SearchPage() {
    const { searchPostResults, isSearchLoading } = useSearchContext();

    return (


        <Box >
            {isSearchLoading || (!isSearchLoading && searchPostResults.length > 0) ?
                <>
                    <Flex w="full" justify="center" p={5}>Search Results:</Flex>
                    <Feed
                        posts={
                            searchPostResults.map((post: Post) =>
                                <PostComponent key={post.uuid} post={post} />
                            )
                        }

                        isLoading={isSearchLoading} />
                </>
                :
                <Flex w="full" justify="center" p={10}>No search results</Flex>

            }
        </Box>
    )
}




// Previous search results page
// <Flex alignItems="center" direction="column" p={20} >

//     <Flex direction="column" alignItems="center" borderWidth={1} width="70vw" minH="50vh" borderRadius={8} >

//         {/*Create Image*/}
//         <Flex w="full" direction="column" alignItems="center" py={10} gap={4}>
//             {searchPostResults.map(post => (
//                 // <Image key={post.uuid} src={post.images[0].url} alt={post.text} />
//                 <Flex w="full" key={post.uuid} py={4} px={8} alignItems="center" justify="space-between" >
//                     <Box boxSize={200}>
//                         <Image src={post.images[0].url} alt={post.text} borderRadius={8} />
//                     </Box>
//                     <Box w={300}>{post.text}</Box>
//                 </Flex>

//             ))}
//         </Flex>
//     </Flex >
// </Flex >
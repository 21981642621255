/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ClerkUser
 */
export interface ClerkUser {
    /**
     * 
     * @type {string}
     * @memberof ClerkUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClerkUser
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClerkUser
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClerkUser
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof ClerkUser
     */
    'image_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClerkUser
     */
    'created_at': number;
    /**
     * 
     * @type {Array<EmailAddress>}
     * @memberof ClerkUser
     */
    'email_addresses': Array<EmailAddress>;
    /**
     * 
     * @type {string}
     * @memberof ClerkUser
     */
    'primary_email_address_id': string;
}
/**
 * 
 * @export
 * @interface CreatePostRequestPayload
 */
export interface CreatePostRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequestPayload
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePostRequestPayload
     */
    'image_uuids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequestPayload
     */
    'parent_post_uuid'?: string;
}
/**
 * 
 * @export
 * @interface CreatePostResponsePayload
 */
export interface CreatePostResponsePayload {
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponsePayload
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface DeleteImageRequestPayload
 */
export interface DeleteImageRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof DeleteImageRequestPayload
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface DeletePostRequestPayload
 */
export interface DeletePostRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof DeletePostRequestPayload
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    'email_address': string;
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GetCannyTokenResponse
 */
export interface GetCannyTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCannyTokenResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface GetImageProgressRequestPayload
 */
export interface GetImageProgressRequestPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetImageProgressRequestPayload
     */
    'uuids': Array<string>;
}
/**
 * 
 * @export
 * @interface GetImageProgressResponse
 */
export interface GetImageProgressResponse {
    /**
     * 
     * @type {{ [key: string]: Image; }}
     * @memberof GetImageProgressResponse
     */
    'data': { [key: string]: Image; };
}
/**
 * 
 * @export
 * @interface GetImagesRequest
 */
export interface GetImagesRequest {
    /**
     * 
     * @type {number}
     * @memberof GetImagesRequest
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface GetImagesResponse
 */
export interface GetImagesResponse {
    /**
     * 
     * @type {Array<Image>}
     * @memberof GetImagesResponse
     */
    'images': Array<Image>;
}
/**
 * 
 * @export
 * @interface GetPostRequestPayload
 */
export interface GetPostRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof GetPostRequestPayload
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface GetPostResponsePayload
 */
export interface GetPostResponsePayload {
    /**
     * 
     * @type {Post}
     * @memberof GetPostResponsePayload
     */
    'post'?: Post;
}
/**
 * 
 * @export
 * @interface GetPostsRequestPayload
 */
export interface GetPostsRequestPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPostsRequestPayload
     */
    'uuids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPostsRequestPayload
     */
    'parent_post_uuids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetPostsRequestPayload
     */
    'author_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPostsRequestPayload
     */
    'liked_by_user_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPostsRequestPayload
     */
    'offset'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostsRequestPayload
     */
    'order_by_popular'?: boolean;
}
/**
 * 
 * @export
 * @interface GetPostsResponsePayload
 */
export interface GetPostsResponsePayload {
    /**
     * 
     * @type {Array<Post>}
     * @memberof GetPostsResponsePayload
     */
    'posts': Array<Post>;
}
/**
 * 
 * @export
 * @interface GetUserRequestPayload
 */
export interface GetUserRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof GetUserRequestPayload
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface GetUserResponsePayload
 */
export interface GetUserResponsePayload {
    /**
     * 
     * @type {User}
     * @memberof GetUserResponsePayload
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'prompt': string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'user_uuid': string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    'progress_percent': number;
}
/**
 * 
 * @export
 * @interface ImageCreateRequestPayload
 */
export interface ImageCreateRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof ImageCreateRequestPayload
     */
    'prompt': string;
}
/**
 * 
 * @export
 * @interface ImageCreateRequestPayloadV2
 */
export interface ImageCreateRequestPayloadV2 {
    /**
     * 
     * @type {string}
     * @memberof ImageCreateRequestPayloadV2
     */
    'prompt': string;
}
/**
 * 
 * @export
 * @interface ImageProgress
 */
export interface ImageProgress {
    /**
     * 
     * @type {string}
     * @memberof ImageProgress
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImageProgress
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ImageProgress
     */
    'status': string;
    /**
     * 
     * @type {object}
     * @memberof ImageProgress
     */
    'input': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImageProgress
     */
    'output'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ImageProgress
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageProgress
     */
    'logs'?: string;
    /**
     * 
     * @type {object}
     * @memberof ImageProgress
     */
    'metrics'?: object;
}
/**
 * 
 * @export
 * @interface ImageProgressRequestPayloadV2
 */
export interface ImageProgressRequestPayloadV2 {
    /**
     * 
     * @type {string}
     * @memberof ImageProgressRequestPayloadV2
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof ImageProgressRequestPayloadV2
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ImageProgressRequestPayloadV2
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageProgressRequestPayloadV2
     */
    'progress_percent': number;
    /**
     * 
     * @type {number}
     * @memberof ImageProgressRequestPayloadV2
     */
    'eta': number;
}
/**
 * 
 * @export
 * @interface LikePostRequestPayload
 */
export interface LikePostRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof LikePostRequestPayload
     */
    'post_uuid': string;
}
/**
 * 
 * @export
 * @interface Post
 */
export interface Post {
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    'uuid': string;
    /**
     * 
     * @type {User}
     * @memberof Post
     */
    'author': User;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    'text': string;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Post
     */
    'images': Array<Image>;
    /**
     * 
     * @type {number}
     * @memberof Post
     */
    'likes': number;
    /**
     * 
     * @type {number}
     * @memberof Post
     */
    'flags': number;
    /**
     * 
     * @type {boolean}
     * @memberof Post
     */
    'caller_liked': boolean;
    /**
     * 
     * @type {number}
     * @memberof Post
     */
    'num_comments': number;
}
/**
 * 
 * @export
 * @interface SearchRequestPayload
 */
export interface SearchRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof SearchRequestPayload
     */
    'search_term': string;
}
/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {Array<Image>}
     * @memberof SearchResponse
     */
    'images': Array<Image>;
    /**
     * 
     * @type {Array<Post>}
     * @memberof SearchResponse
     */
    'posts': Array<Post>;
}
/**
 * 
 * @export
 * @interface UnlikePostRequestPayload
 */
export interface UnlikePostRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof UnlikePostRequestPayload
     */
    'post_uuid': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'image_url': string;
}
/**
 * 
 * @export
 * @interface UserRequestPayload
 */
export interface UserRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof UserRequestPayload
     */
    'object': string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestPayload
     */
    'type': string;
    /**
     * 
     * @type {ClerkUser}
     * @memberof UserRequestPayload
     */
    'data': ClerkUser;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Image
         * @param {ImageCreateRequestPayload} imageCreateRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage: async (imageCreateRequestPayload: ImageCreateRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageCreateRequestPayload' is not null or undefined
            assertParamExists('createImage', 'imageCreateRequestPayload', imageCreateRequestPayload)
            const localVarPath = `/img`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageCreateRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Image V2
         * @param {ImageCreateRequestPayloadV2} imageCreateRequestPayloadV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImageV2: async (imageCreateRequestPayloadV2: ImageCreateRequestPayloadV2, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageCreateRequestPayloadV2' is not null or undefined
            assertParamExists('createImageV2', 'imageCreateRequestPayloadV2', imageCreateRequestPayloadV2)
            const localVarPath = `/img/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageCreateRequestPayloadV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Post
         * @param {CreatePostRequestPayload} createPostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost: async (createPostRequestPayload: CreatePostRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPostRequestPayload' is not null or undefined
            assertParamExists('createPost', 'createPostRequestPayload', createPostRequestPayload)
            const localVarPath = `/post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPostRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User
         * @param {UserRequestPayload} userRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userRequestPayload: UserRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestPayload' is not null or undefined
            assertParamExists('createUser', 'userRequestPayload', userRequestPayload)
            const localVarPath = `/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Image
         * @param {DeleteImageRequestPayload} deleteImageRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage: async (deleteImageRequestPayload: DeleteImageRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteImageRequestPayload' is not null or undefined
            assertParamExists('deleteImage', 'deleteImageRequestPayload', deleteImageRequestPayload)
            const localVarPath = `/img/delete-img`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteImageRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Post
         * @param {DeletePostRequestPayload} deletePostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost: async (deletePostRequestPayload: DeletePostRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePostRequestPayload' is not null or undefined
            assertParamExists('deletePost', 'deletePostRequestPayload', deletePostRequestPayload)
            const localVarPath = `/post/delete-post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePostRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User
         * @param {UserRequestPayload} userRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userRequestPayload: UserRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestPayload' is not null or undefined
            assertParamExists('deleteUser', 'userRequestPayload', userRequestPayload)
            const localVarPath = `/user/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Canny Token
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCannyToken: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/canny/get-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Image Progress
         * @param {GetImageProgressRequestPayload} getImageProgressRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageProgress: async (getImageProgressRequestPayload: GetImageProgressRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getImageProgressRequestPayload' is not null or undefined
            assertParamExists('getImageProgress', 'getImageProgressRequestPayload', getImageProgressRequestPayload)
            const localVarPath = `/img/get-progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getImageProgressRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Images
         * @param {GetImagesRequest} getImagesRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImages: async (getImagesRequest: GetImagesRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getImagesRequest' is not null or undefined
            assertParamExists('getImages', 'getImagesRequest', getImagesRequest)
            const localVarPath = `/img/get-imgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getImagesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Post
         * @param {GetPostRequestPayload} getPostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPost: async (getPostRequestPayload: GetPostRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPostRequestPayload' is not null or undefined
            assertParamExists('getPost', 'getPostRequestPayload', getPostRequestPayload)
            const localVarPath = `/post/get-post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPostRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Posts
         * @param {GetPostsRequestPayload} getPostsRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts: async (getPostsRequestPayload: GetPostsRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPostsRequestPayload' is not null or undefined
            assertParamExists('getPosts', 'getPostsRequestPayload', getPostsRequestPayload)
            const localVarPath = `/post/get-posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPostsRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User
         * @param {GetUserRequestPayload} getUserRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (getUserRequestPayload: GetUserRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getUserRequestPayload' is not null or undefined
            assertParamExists('getUser', 'getUserRequestPayload', getUserRequestPayload)
            const localVarPath = `/user/get-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getUserRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Like Post
         * @param {LikePostRequestPayload} likePostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePost: async (likePostRequestPayload: LikePostRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'likePostRequestPayload' is not null or undefined
            assertParamExists('likePost', 'likePostRequestPayload', likePostRequestPayload)
            const localVarPath = `/post/like`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(likePostRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search
         * @param {SearchRequestPayload} searchRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (searchRequestPayload: SearchRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequestPayload' is not null or undefined
            assertParamExists('search', 'searchRequestPayload', searchRequestPayload)
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlike Post
         * @param {UnlikePostRequestPayload} unlikePostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikePost: async (unlikePostRequestPayload: UnlikePostRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unlikePostRequestPayload' is not null or undefined
            assertParamExists('unlikePost', 'unlikePostRequestPayload', unlikePostRequestPayload)
            const localVarPath = `/post/unlike`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unlikePostRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Image Progress
         * @param {ImageProgress} imageProgress 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImageProgress: async (imageProgress: ImageProgress, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageProgress' is not null or undefined
            assertParamExists('updateImageProgress', 'imageProgress', imageProgress)
            const localVarPath = `/img/progress-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageProgress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Image Progress
         * @param {ImageProgressRequestPayloadV2} imageProgressRequestPayloadV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImageProgressV2: async (imageProgressRequestPayloadV2: ImageProgressRequestPayloadV2, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageProgressRequestPayloadV2' is not null or undefined
            assertParamExists('updateImageProgressV2', 'imageProgressRequestPayloadV2', imageProgressRequestPayloadV2)
            const localVarPath = `/img/v2/progress-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageProgressRequestPayloadV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {UserRequestPayload} userRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userRequestPayload: UserRequestPayload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequestPayload' is not null or undefined
            assertParamExists('updateUser', 'userRequestPayload', userRequestPayload)
            const localVarPath = `/user/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Image
         * @param {ImageCreateRequestPayload} imageCreateRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImage(imageCreateRequestPayload: ImageCreateRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImage(imageCreateRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Image V2
         * @param {ImageCreateRequestPayloadV2} imageCreateRequestPayloadV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImageV2(imageCreateRequestPayloadV2: ImageCreateRequestPayloadV2, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImageV2(imageCreateRequestPayloadV2, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Post
         * @param {CreatePostRequestPayload} createPostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPost(createPostRequestPayload: CreatePostRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePostResponsePayload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPost(createPostRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create User
         * @param {UserRequestPayload} userRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userRequestPayload: UserRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Image
         * @param {DeleteImageRequestPayload} deleteImageRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImage(deleteImageRequestPayload: DeleteImageRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImage(deleteImageRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Post
         * @param {DeletePostRequestPayload} deletePostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePost(deletePostRequestPayload: DeletePostRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePost(deletePostRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete User
         * @param {UserRequestPayload} userRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userRequestPayload: UserRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Canny Token
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCannyToken(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCannyTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCannyToken(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Image Progress
         * @param {GetImageProgressRequestPayload} getImageProgressRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageProgress(getImageProgressRequestPayload: GetImageProgressRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetImageProgressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageProgress(getImageProgressRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Images
         * @param {GetImagesRequest} getImagesRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImages(getImagesRequest: GetImagesRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetImagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImages(getImagesRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Post
         * @param {GetPostRequestPayload} getPostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPost(getPostRequestPayload: GetPostRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPostResponsePayload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPost(getPostRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Posts
         * @param {GetPostsRequestPayload} getPostsRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPosts(getPostsRequestPayload: GetPostsRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPostsResponsePayload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPosts(getPostsRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User
         * @param {GetUserRequestPayload} getUserRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(getUserRequestPayload: GetUserRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponsePayload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(getUserRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Like Post
         * @param {LikePostRequestPayload} likePostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likePost(likePostRequestPayload: LikePostRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likePost(likePostRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search
         * @param {SearchRequestPayload} searchRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(searchRequestPayload: SearchRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(searchRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlike Post
         * @param {UnlikePostRequestPayload} unlikePostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlikePost(unlikePostRequestPayload: UnlikePostRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlikePost(unlikePostRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Image Progress
         * @param {ImageProgress} imageProgress 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateImageProgress(imageProgress: ImageProgress, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateImageProgress(imageProgress, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Image Progress
         * @param {ImageProgressRequestPayloadV2} imageProgressRequestPayloadV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateImageProgressV2(imageProgressRequestPayloadV2: ImageProgressRequestPayloadV2, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateImageProgressV2(imageProgressRequestPayloadV2, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User
         * @param {UserRequestPayload} userRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userRequestPayload: UserRequestPayload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userRequestPayload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Image
         * @param {ImageCreateRequestPayload} imageCreateRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(imageCreateRequestPayload: ImageCreateRequestPayload, authorization?: string, options?: any): AxiosPromise<Image> {
            return localVarFp.createImage(imageCreateRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Image V2
         * @param {ImageCreateRequestPayloadV2} imageCreateRequestPayloadV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImageV2(imageCreateRequestPayloadV2: ImageCreateRequestPayloadV2, authorization?: string, options?: any): AxiosPromise<Image> {
            return localVarFp.createImageV2(imageCreateRequestPayloadV2, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Post
         * @param {CreatePostRequestPayload} createPostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost(createPostRequestPayload: CreatePostRequestPayload, authorization?: string, options?: any): AxiosPromise<CreatePostResponsePayload> {
            return localVarFp.createPost(createPostRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User
         * @param {UserRequestPayload} userRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userRequestPayload: UserRequestPayload, authorization?: string, options?: any): AxiosPromise<any> {
            return localVarFp.createUser(userRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Image
         * @param {DeleteImageRequestPayload} deleteImageRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(deleteImageRequestPayload: DeleteImageRequestPayload, authorization?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteImage(deleteImageRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Post
         * @param {DeletePostRequestPayload} deletePostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost(deletePostRequestPayload: DeletePostRequestPayload, authorization?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deletePost(deletePostRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User
         * @param {UserRequestPayload} userRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userRequestPayload: UserRequestPayload, authorization?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteUser(userRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Canny Token
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCannyToken(authorization?: string, options?: any): AxiosPromise<GetCannyTokenResponse> {
            return localVarFp.getCannyToken(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Image Progress
         * @param {GetImageProgressRequestPayload} getImageProgressRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageProgress(getImageProgressRequestPayload: GetImageProgressRequestPayload, authorization?: string, options?: any): AxiosPromise<GetImageProgressResponse> {
            return localVarFp.getImageProgress(getImageProgressRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Images
         * @param {GetImagesRequest} getImagesRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImages(getImagesRequest: GetImagesRequest, authorization?: string, options?: any): AxiosPromise<GetImagesResponse> {
            return localVarFp.getImages(getImagesRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Post
         * @param {GetPostRequestPayload} getPostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPost(getPostRequestPayload: GetPostRequestPayload, authorization?: string, options?: any): AxiosPromise<GetPostResponsePayload> {
            return localVarFp.getPost(getPostRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Posts
         * @param {GetPostsRequestPayload} getPostsRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts(getPostsRequestPayload: GetPostsRequestPayload, authorization?: string, options?: any): AxiosPromise<GetPostsResponsePayload> {
            return localVarFp.getPosts(getPostsRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User
         * @param {GetUserRequestPayload} getUserRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(getUserRequestPayload: GetUserRequestPayload, authorization?: string, options?: any): AxiosPromise<GetUserResponsePayload> {
            return localVarFp.getUser(getUserRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Like Post
         * @param {LikePostRequestPayload} likePostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePost(likePostRequestPayload: LikePostRequestPayload, authorization?: string, options?: any): AxiosPromise<any> {
            return localVarFp.likePost(likePostRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search
         * @param {SearchRequestPayload} searchRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(searchRequestPayload: SearchRequestPayload, authorization?: string, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.search(searchRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlike Post
         * @param {UnlikePostRequestPayload} unlikePostRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikePost(unlikePostRequestPayload: UnlikePostRequestPayload, authorization?: string, options?: any): AxiosPromise<any> {
            return localVarFp.unlikePost(unlikePostRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Image Progress
         * @param {ImageProgress} imageProgress 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImageProgress(imageProgress: ImageProgress, authorization?: string, options?: any): AxiosPromise<any> {
            return localVarFp.updateImageProgress(imageProgress, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Image Progress
         * @param {ImageProgressRequestPayloadV2} imageProgressRequestPayloadV2 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImageProgressV2(imageProgressRequestPayloadV2: ImageProgressRequestPayloadV2, authorization?: string, options?: any): AxiosPromise<any> {
            return localVarFp.updateImageProgressV2(imageProgressRequestPayloadV2, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {UserRequestPayload} userRequestPayload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userRequestPayload: UserRequestPayload, authorization?: string, options?: any): AxiosPromise<any> {
            return localVarFp.updateUser(userRequestPayload, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Create Image
     * @param {ImageCreateRequestPayload} imageCreateRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createImage(imageCreateRequestPayload: ImageCreateRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createImage(imageCreateRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Image V2
     * @param {ImageCreateRequestPayloadV2} imageCreateRequestPayloadV2 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createImageV2(imageCreateRequestPayloadV2: ImageCreateRequestPayloadV2, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createImageV2(imageCreateRequestPayloadV2, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Post
     * @param {CreatePostRequestPayload} createPostRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPost(createPostRequestPayload: CreatePostRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPost(createPostRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User
     * @param {UserRequestPayload} userRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUser(userRequestPayload: UserRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createUser(userRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Image
     * @param {DeleteImageRequestPayload} deleteImageRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteImage(deleteImageRequestPayload: DeleteImageRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteImage(deleteImageRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Post
     * @param {DeletePostRequestPayload} deletePostRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePost(deletePostRequestPayload: DeletePostRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deletePost(deletePostRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User
     * @param {UserRequestPayload} userRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUser(userRequestPayload: UserRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteUser(userRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Canny Token
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCannyToken(authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCannyToken(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Image Progress
     * @param {GetImageProgressRequestPayload} getImageProgressRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getImageProgress(getImageProgressRequestPayload: GetImageProgressRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getImageProgress(getImageProgressRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Images
     * @param {GetImagesRequest} getImagesRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getImages(getImagesRequest: GetImagesRequest, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getImages(getImagesRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Post
     * @param {GetPostRequestPayload} getPostRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPost(getPostRequestPayload: GetPostRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPost(getPostRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Posts
     * @param {GetPostsRequestPayload} getPostsRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPosts(getPostsRequestPayload: GetPostsRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPosts(getPostsRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User
     * @param {GetUserRequestPayload} getUserRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(getUserRequestPayload: GetUserRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUser(getUserRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthCheck(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Like Post
     * @param {LikePostRequestPayload} likePostRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public likePost(likePostRequestPayload: LikePostRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).likePost(likePostRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search
     * @param {SearchRequestPayload} searchRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public search(searchRequestPayload: SearchRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).search(searchRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlike Post
     * @param {UnlikePostRequestPayload} unlikePostRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unlikePost(unlikePostRequestPayload: UnlikePostRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unlikePost(unlikePostRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Image Progress
     * @param {ImageProgress} imageProgress 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateImageProgress(imageProgress: ImageProgress, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateImageProgress(imageProgress, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Image Progress
     * @param {ImageProgressRequestPayloadV2} imageProgressRequestPayloadV2 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateImageProgressV2(imageProgressRequestPayloadV2: ImageProgressRequestPayloadV2, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateImageProgressV2(imageProgressRequestPayloadV2, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {UserRequestPayload} userRequestPayload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUser(userRequestPayload: UserRequestPayload, authorization?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUser(userRequestPayload, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}



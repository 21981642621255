import { Box, Button, useColorMode } from "@chakra-ui/react";
import { Navigate, useLocation } from 'react-router-dom';
import { SignedOut, useAuth } from "@clerk/clerk-react";
import { useImagesContext } from "hooks/useImages";
import { Sidebar } from "./components/Sidebar";
import Header from "./components/Header";
import LoginSignUpModalsWrapper from "./components/LoginSIgnUpModalsWrapper";
import { useEffect, useState } from "react";
// import BoutikbootyApiClient from "clients/BoutikbootyApiClient";
import useSidebar from "hooks/useSidebar";
import { CreatePage } from "pages/CreatePage";
import HomePage from "pages/HomePage";
import { ProfilePage } from "pages/ProfilePage";
import { Routes, Route } from "react-router-dom";
import FeedbackPage from "pages/FeedbackPage";
import useCannySDK from "hooks/third_party/useCannySDK";
import RootLayout from "layouts/RootLayout";
import { useSignUpModal, useLoginModal } from "hooks/useModalHooks";
import { CreatePostModal } from "components/posts/CreatePostModal";
import { SearchPage } from "pages/SearchPage";
import { PostPage } from "pages/PostPage";
import { useCreatePostModalContext } from "hooks/useCreatePostModal";


function AppManager() {
    useCannySDK();  // canny feedback page

    const { isSignUpOpen, onSignUpOpen, onSignUpClose } = useSignUpModal();
    const { isLoginOpen, onLoginOpen, onLoginClose } = useLoginModal();

    // const { isSidebarOpen, onSidebarOpen, onSidebarClose } = useSidebar()
    const { colorMode, toggleColorMode } = useColorMode()
    const { isCreatePostOpen, onCreatePostClose } = useCreatePostModalContext();


    useEffect(() => {
        if (colorMode === 'light') {
            toggleColorMode()
        }
    }, [colorMode, toggleColorMode])


    return (
        <Box bg="gray.800" >
            <Header onOpenModal={onLoginOpen} />

            <SignedOut>
                <LoginSignUpModalsWrapper
                    isLoginOpen={isLoginOpen}
                    onLoginOpen={onLoginOpen}
                    onLoginClose={onLoginClose}
                    isSignUpOpen={isSignUpOpen}
                    onSignUpOpen={onSignUpOpen}
                    onSignUpClose={onSignUpClose} />
            </SignedOut>

            <CreatePostModal isOpen={isCreatePostOpen} onClose={onCreatePostClose} />

            <Routes >
                <Route element={<RootLayout />}>
                    <Route path="/" element={<HomePage />} />

                    <Route path="/search" element={<SearchPage />} />

                    <Route path="/profile">
                        <Route index element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />

                        <Route path=":userUuid" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
                    </Route>

                    <Route path="/create" element={<ProtectedRoute><CreatePage /></ProtectedRoute>} />

                    <Route path="/feedback" element={<ProtectedRoute><FeedbackPage /></ProtectedRoute>} />

                    <Route path="/posts/:postUuid" element={<PostPage />} />

                    <Route path="*" element={<Navigate to="/" replace />} />

                </Route>
            </Routes>

            {/* <Sidebar isOpen={isSidebarOpen} onClose={onSidebarClose} />

            {imagesInProgress.length > 0 && !location.pathname.startsWith("/create") ?
                <Button
                    onClick={onSidebarOpen}
                    position="fixed"
                    top={16}
                    right={4}
                >
                    Image Queue
                </Button> : null
            } */}

        </Box>
    );
}

export default AppManager;

interface AppRoutesProps {
}


function ProtectedRoute({ children }: { children: React.ReactNode }) {
    const { isSignedIn, isLoaded } = useAuth();

    // If the authentication status is still being confirmed, don't navigate away
    if (!isLoaded) return null;

    return (
        <>
            {isSignedIn ? children : <Navigate to="/" />}
        </>
    );
}

